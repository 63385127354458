import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { MockedResponse } from '@apollo/client/testing'

export type ItemOfferBadgeInput = {
  input: {
    itemGuid: string
    restaurantGuid: string
  }
}

export interface ItemOfferBadge {
  bannerGuid: string
  bannerText: string
  itemGuid: string
  promoCode: string
}

export type ItemOfferBadgeData = {
  itemOfferBadges: ItemOfferBadge[]
  __typename: 'ItemDetailOfferBadgeResponse'
}

export type ItemOfferBadgeResponse = {
  itemOfferBadges: ItemOfferBadgeData
}

export type GetItemOfferBadgesQueryOptions = QueryHookOptions<
  ItemOfferBadgeResponse,
  ItemOfferBadgeInput
>

export const GET_ITEM_OFFER_BADGES = gql`
  query GET_ITEM_OFFER_BADGES($input: ItemDetailOfferBadgeInput!) {
    itemOfferBadges(input: $input) {
      ... on ItemDetailOfferBadgeResponse {
        itemOfferBadges {
          bannerGuid
          bannerText
          itemGuid
          promoCode
        }
      }

      ... on GeneralError {
        code
        message
      }
    }
  }
`

export const useGetItemOfferBadges = (
  queryOptions: GetItemOfferBadgesQueryOptions = {}
) =>
  useQuery<ItemOfferBadgeResponse, ItemOfferBadgeInput>(
    GET_ITEM_OFFER_BADGES,
    queryOptions
  )

// for mocking
export const newGetItemOfferBadgesResponse = (
  restaurantGuid: string,
  itemGuid: string,
  data: ItemOfferBadgeResponse | Error
): MockedResponse<ItemOfferBadgeResponse> => {
  return {
    request: {
      query: GET_ITEM_OFFER_BADGES,
      variables: {
        input: {
          itemGuid,
          restaurantGuid
        }
      }
    },
    result: { data: data instanceof Error ? undefined : data },
    error: data instanceof Error ? data : undefined
  }
}

export const newGetItemOfferBadgesResponseData = (
  itemOfferBadges: ItemOfferBadge[]
): ItemOfferBadgeResponse => {
  return {
    itemOfferBadges: {
      itemOfferBadges,
      __typename: 'ItemDetailOfferBadgeResponse'
    }
  }
}

export const createMockItemOfferBadge = (
  overrides: Partial<ItemOfferBadge> = {}
): ItemOfferBadge => ({
  bannerGuid: 'bannerGuid',
  bannerText: '50% off this item',
  itemGuid: 'itemGuid',
  promoCode: '50_OFF',
  ...overrides
})
