import * as React from 'react'
import { IBanquetInitialData } from '@toasttab/do-secundo-cornucopia-types'

export interface OOGlobalProps extends IBanquetInitialData {
  isCornucopiaShellRunning?: boolean
  shortUrl?: string
  restaurantGuid?: string
  ooGlobals?: {
    mode: string
    shortUrl: string
    routerBasename: string
    restaurantGuid: string
    enabledFeatureFlags: string[]
  }
  name?: string
}

export const OOGlobalPropsContext = React.createContext<OOGlobalProps>({})

export const useOOGlobalPropsContext = () => {
  return React.useContext(OOGlobalPropsContext)
}

export type OOGlobalPropsProviderProps = {
  children: React.ReactNode
  ooGlobalProps: OOGlobalProps
}

export const OOGlobalPropsProvider = ({
  children,
  ooGlobalProps
}: OOGlobalPropsProviderProps) => (
  <OOGlobalPropsContext.Provider value={ooGlobalProps}>
    {children}
  </OOGlobalPropsContext.Provider>
)
