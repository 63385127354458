import React from 'react'
import { toast } from 'react-toastify'

import {
  dispatchAppliedPromoCode,
  dispatchPromoBannerSeen,
  dispatchRemoveAppliedPromoCode,
  updateCartRequest$
} from 'cornucopia-apis'
import { DoneIcon, WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { getErrorMessage, logError, Toast } from '@local/logging'
import {
  ItemOfferBadge as ItemOfferBadgeType,
  useApplyPromoCode
} from '@local/service'
import { track } from '@toasttab/do-secundo-analytics'
import { useBanquetProps } from 'banquet-runtime-modules'
import { itemOfferBadgeDataToBanner } from './ItemOfferBadge'

const TOAST_OPTIONS = {
  autoClose: 2500,
  delay: 1500
}

export type CartListenerProps = {
  itemBadge: ItemOfferBadgeType
}

/**
 * Helper to manage the lifecycle of the item badge component
 * It will apply a promo code based on the cart requests/responses while mounted
 * It requires we unsubscribe and resubscribe when we rerender to avoid subscribing more than once.
 *
 * THIS IS A TEMPORARY SOLUTION
 * Ideally this should be done on the BE by adding an optional code to the item add request
 * for now we're doing this in the short term and are working with other teams to complete the other work
 * Tracking ticket: https://toasttab.atlassian.net/browse/POE-320
 */
export const CartListener = ({ itemBadge }: CartListenerProps) => {
  const { restaurantInfo: { restaurantGuid = '' } = {} } = useBanquetProps()

  const [applyPromoCode, { loading: applyingPromoCode }] = useApplyPromoCode({
    onCompleted: ({ applyPromoCodeV3: response }) => {
      dispatchRemoveAppliedPromoCode(restaurantGuid)
      switch (response.__typename) {
        case 'CartResponse':
          toast(
            <Toast Icon={DoneIcon}>Promo code applied</Toast>,
            TOAST_OPTIONS
          )
          break
        case 'CartOutOfStockError':
          toast(
            <Toast Icon={WarningOutlineIcon}>Item out of stock</Toast>,
            TOAST_OPTIONS
          )
          break
        case 'ApplyPromoCodeError':
        case 'CartModificationError':
        default:
          toast(
            <Toast Icon={WarningOutlineIcon}>Error applying promo code</Toast>,
            TOAST_OPTIONS
          )
          break
      }
    },
    onError: (error) => {
      dispatchRemoveAppliedPromoCode(restaurantGuid)
      toast(
        <Toast Icon={WarningOutlineIcon}>Error applying promo code</Toast>,
        TOAST_OPTIONS
      )
      logError(error)
    }
  })

  React.useEffect(() => {
    if (!restaurantGuid) {
      return
    }

    // dispatch promo code that will be applied to session to notify other listeners
    // this is used in takeout-web to not validate the cart when applying the promo code
    try {
      dispatchAppliedPromoCode(restaurantGuid, {
        bannerGuid: itemBadge.bannerGuid,
        promoCode: itemBadge.promoCode
      })
      // metrics: track item banner view and its promo code (for checkout metrics)
      track('Item Detail Banner - seen', itemBadge)
      dispatchPromoBannerSeen(
        restaurantGuid,
        itemOfferBadgeDataToBanner(itemBadge)
      )
    } catch (error) {
      dispatchRemoveAppliedPromoCode(restaurantGuid)
      logError({ message: getErrorMessage(error) })
    }

    // subscribe to cart updates and apply a promo code after a successful add item to cart response for given itemGuid
    let cartResponseSubscription: any
    const updateCartSubscription = updateCartRequest$.subscribe(
      (cartRequest: any) => {
        cartResponseSubscription = cartRequest?.header?.response$.subscribe(
          (cartResponse: any) => {
            const cartGuid = cartResponse?.cart?.guid
            const canApplyPromoCode =
              cartGuid &&
              !cartRequest?.selectionGuid &&
              cartResponse?.kind === 'OK' &&
              cartRequest?.selection?.itemGuid === itemBadge.itemGuid

            if (!cartGuid) {
              toast(
                <Toast Icon={WarningOutlineIcon}>
                  Error applying promo code
                </Toast>,
                TOAST_OPTIONS
              )
              return
            }

            if (!canApplyPromoCode) {
              return
            }

            applyPromoCode({
              variables: {
                input: {
                  cartGuid,
                  promoCode: itemBadge.promoCode
                }
              }
            })
          }
        )
      }
    )

    return () => {
      updateCartSubscription?.unsubscribe()
      cartResponseSubscription?.unsubscribe()
      if (!applyingPromoCode) {
        dispatchRemoveAppliedPromoCode(restaurantGuid)
      }
    }
  }, [applyPromoCode, applyingPromoCode, itemBadge, restaurantGuid])

  return <></>
}
