import React from 'react'

import {
  DeliveryBanner,
  IDeliveryBanner,
  ILoyaltyBanner,
  IPromoCodeBanner,
  LoyaltyBanner
} from '@toasttab/buffet-patterns-promo-banners'
import { PromoCodeBannerContainer } from '../PromoCodeBannerContainer'

export type PromoBannerType =
  | IDeliveryBanner
  | ILoyaltyBanner
  | IPromoCodeBanner

export interface PromoBannerProps {
  banner: PromoBannerType
  className?: string
  testId: string
}

export const PromoBanner = ({ banner, ...sharedProps }: PromoBannerProps) => {
  switch (banner.type) {
    case 'DELIVERY':
      return <DeliveryBanner banner={banner} {...sharedProps} />
    case 'LOYALTY':
    case 'DINING_BONUS':
      return <LoyaltyBanner banner={banner} {...sharedProps} />
    case 'PROMO_CODE':
    case 'PROMO_CODE_ITEM':
      return <PromoCodeBannerContainer banner={banner} {...sharedProps} />
    default:
      return <></>
  }
}
