import React from 'react'

import { logError } from '@local/logging'
import {
  PromoBannerData,
  PromoBannersParams,
  PromoBannersParamsProvider,
  useGetPromoBanners
} from '@local/service'
import { PromoBannersCarousel } from '../PromoBannersCarousel'
import { track } from '@toasttab/do-secundo-analytics'
import { useBanquetProps } from 'banquet-runtime-modules'

export const PromoBanners = (params: PromoBannersParams) => {
  const { restaurantInfo: { restaurantGuid } = {} } = useBanquetProps()

  const {
    data: { promoBanners } = {},
    error: bannersQueryError,
    loading
  } = useGetPromoBanners({
    onCompleted: (data) => {
      if (getAllBanners(data.promoBanners).length) {
        track('Promo Banner - available', {})
      }
    },
    onError: (error) => {
      logError(error)
    },
    skip: !restaurantGuid
  })

  if (loading || bannersQueryError || !promoBanners) {
    return <></>
  }

  return (
    <PromoBannersParamsProvider params={params}>
      <div data-pb='true'>
        <PromoBannersCarousel promoBanners={getAllBanners(promoBanners)} />
      </div>
    </PromoBannersParamsProvider>
  )
}

export const getAllBanners = (promoBannersData: PromoBannerData) => [
  ...(promoBannersData.promoCodeBanners || []),
  ...(promoBannersData.deliveryBanners || []),
  ...(promoBannersData.loyaltyBanners || [])
]
