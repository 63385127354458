import * as React from 'react'

export interface PromoBannersCarouselConfig {
  // handles visibility of pagination controls
  pagination?: boolean
  // space in px between slides
  slideGap?: number
  // left and right offset of slides, used for displaying parts of next & previous slides, if needed
  xOffset?: number
}

export interface PromoBannersParams {
  carouselConfig?: PromoBannersCarouselConfig
  cartGuid?: string
}

export const PromoBannersParamsContext =
  React.createContext<PromoBannersParams>({})

export const usePromoBannersParamsContext = () => {
  return React.useContext(PromoBannersParamsContext)
}

export type PromoBannersParamsProviderProps = {
  children: React.ReactNode
  params: PromoBannersParams
}

export const PromoBannersParamsProvider = ({
  children,
  params
}: PromoBannersParamsProviderProps) => (
  <PromoBannersParamsContext.Provider value={params}>
    {children}
  </PromoBannersParamsContext.Provider>
)
