import React from 'react'
import cx from 'classnames'

import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'

export type PromoBannersCarouselPaginationProps = React.ComponentPropsWithRef<
  typeof IconButton
>

export type ConfigurablePaginationProps = Pick<
  PromoBannersCarouselPaginationProps,
  'disabled' | 'onClick' | 'testId'
>

export const PromoBannersCarouselPagination = ({
  disabled = false,
  icon,
  onClick,
  testId = 'promo-banners-carousel-pagination-button',
  title = 'Carousel pagination'
}: PromoBannersCarouselPaginationProps) => (
  <IconButton
    className='flex-shrink-0 bg-transparent border-0'
    data-testid={testId}
    disabled={disabled}
    icon={icon}
    onClick={onClick}
    size='sm'
    title={title}
  />
)

export const PromoBannersCarouselNextPagination = ({
  disabled,
  onClick,
  testId = 'promo-banners-carousel-next-button'
}: ConfigurablePaginationProps) => (
  <PromoBannersCarouselPagination
    testId={testId}
    disabled={disabled}
    icon={
      <ChevronRightIcon
        className={cx({
          'text-disabled': disabled,
          'text-color-default': !disabled
        })}
        size='sm'
        title='right arrow icon'
      />
    }
    onClick={onClick}
    size='sm'
    title='Next banner'
  />
)

export const PromoBannersCarouselPrevPagination = ({
  disabled,
  onClick,
  testId = 'promo-banners-carousel-previous-button'
}: ConfigurablePaginationProps) => (
  <PromoBannersCarouselPagination
    testId={testId}
    disabled={disabled}
    icon={
      <ChevronLeftIcon
        className={cx({
          'text-disabled': disabled,
          'text-color-default': !disabled
        })}
        size='sm'
        title='left arrow icon'
      />
    }
    onClick={onClick}
    title='Previous banner'
  />
)
