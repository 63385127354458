import * as React from 'react'

export interface ItemOfferBadgeParams {
  itemGuid: string
}

export const ItemOfferBadgeParamsContext =
  React.createContext<ItemOfferBadgeParams>({ itemGuid: '' })

export const useItemOfferBadgeParamsContext = () => {
  return React.useContext(ItemOfferBadgeParamsContext)
}

export type ItemOfferBadgeParamsProviderProps = {
  children: React.ReactNode
  params: ItemOfferBadgeParams
}

export const ItemOfferBadgeParamsProvider = ({
  children,
  params
}: ItemOfferBadgeParamsProviderProps) => (
  <ItemOfferBadgeParamsContext.Provider value={params}>
    {children}
  </ItemOfferBadgeParamsContext.Provider>
)
