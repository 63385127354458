import * as React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import { App } from './app/App'
import { banquetSingleSpaReact, initSentry } from 'banquet-runtime-modules'
import { getErrorBoundary, getLogger } from '@toasttab/do-secundo-sentry-client'
import { getSentryConfig } from 'do-cornucopia-root'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'

const tempWindow: any = window
const sentryConfig = getSentryConfig(tempWindow.OO_GLOBALS.mode)
const { hub, client } = initSentry({
  ...sentryConfig,
  releaseVersion: process.env.PKG_VERSION
})

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-pb',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  sentry: sentryConfig,
  initSentry: () => ({ hub, client }),
  // @ts-ignore: Hub type mismatch between do-secundo and banquet - TODO: remove when fixed
  sentryErrorBoundary: getErrorBoundary(hub, { spa: 'corn-promo-banners-spa' })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'corn-promo-banners-spa'
// @ts-ignore: Hub type mismatch between do-secundo and banquet - TODO: remove when fixed
export const logError = getLogger(hub, { spa: 'corn-promo-banners-spa' })
