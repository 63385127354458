import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { MockedResponse } from '@apollo/client/testing'

import {
  IDeliveryBanner,
  ILoyaltyBanner,
  IPromoCodeBanner
} from '@toasttab/buffet-patterns-promo-banners'
import { useBanquetProps } from 'banquet-runtime-modules'

export type PromoBannerInput = {
  input: {
    restaurantGuid: string
    requestSource: string
  }
}

export type PromoBannerData = {
  deliveryBanners: IDeliveryBanner[]
  loyaltyBanners: ILoyaltyBanner[]
  promoCodeBanners: IPromoCodeBanner[]
  __typename: 'PromoBannerResponse'
}

export type PromoBannerResponse = {
  promoBanners: PromoBannerData
}

export type GetPromoBannerQueryOptions = QueryHookOptions<
  PromoBannerResponse,
  PromoBannerInput
>

export const GET_PROMO_BANNERS = gql`
  query GET_PROMO_BANNERS($input: PromoBannerInput!) {
    promoBanners(input: $input) {
      ... on PromoBannerResponse {
        promoCodeBanners {
          bannerGuid
          bannerText
          promoCode
          type
        }

        loyaltyBanners {
          bannerGuid
          bannerText
          subtitle
          type
        }

        deliveryBanners {
          bannerGuid
          bannerText
          type
        }
      }

      ... on GeneralError {
        code
        message
      }
    }
  }
`

export const useGetPromoBanners = (
  queryOptions: GetPromoBannerQueryOptions = {}
) => {
  const { restaurantInfo } = useBanquetProps()

  return useQuery<PromoBannerResponse, PromoBannerInput>(GET_PROMO_BANNERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        restaurantGuid: restaurantInfo?.restaurantGuid || '',
        requestSource: 'ONLINE_ORDERING'
      }
    },
    ...queryOptions
  })
}

// for mocking
export const newGetPromoBannersResponse = (
  restaurantGuid: string,
  data: PromoBannerResponse | Error
): MockedResponse<PromoBannerResponse> => {
  return {
    request: {
      query: GET_PROMO_BANNERS,
      variables: {
        input: {
          restaurantGuid,
          requestSource: 'ONLINE_ORDERING'
        }
      }
    },
    result: { data: data instanceof Error ? undefined : data },
    error: data instanceof Error ? data : undefined
  }
}

export const newGetPromoBannersResponseData = (
  promoBanners: Partial<Omit<PromoBannerData, '__typename'>>
): PromoBannerResponse => {
  const {
    deliveryBanners = [],
    loyaltyBanners = [],
    promoCodeBanners = []
  } = promoBanners

  return {
    promoBanners: {
      deliveryBanners,
      loyaltyBanners,
      promoCodeBanners,
      __typename: 'PromoBannerResponse'
    }
  }
}
