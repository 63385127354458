import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import { dispatchAppliedPromoCode } from 'cornucopia-apis'
import {
  DoneIcon,
  IconProps,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { getErrorMessage, logError, Toast } from '@local/logging'
import {
  IPromoCodeBanner,
  PromoCodeBanner as PromoCodeBannerUIComponent
} from '@toasttab/buffet-patterns-promo-banners'
import {
  useApplyPromoCode,
  useGetCart,
  usePromoBannersParamsContext
} from '@local/service'

import { PromoBannerProps } from '../PromoBanner'

export interface PromoBannerContainerProps extends PromoBannerProps {
  banner: IPromoCodeBanner
}

export const PromoCodeBannerContainer = ({
  banner,
  className,
  testId
}: PromoBannerContainerProps) => {
  const { cartGuid = '' } = usePromoBannersParamsContext()
  const { data: { cartV2: cartResponse } = {} } = useGetCart()
  const appliedPromoCode =
    cartResponse?.cart?.order?.discounts?.restaurantDiscount?.promoCode
  const isApplied = appliedPromoCode === banner.promoCode

  const showToast = useCallback(
    (message: string, Icon?: React.FC<IconProps>) => {
      toast(<Toast Icon={Icon}>{message}</Toast>, { autoClose: 1500 })
    },
    []
  )

  const [applyPromoCode, { loading: isApplying }] = useApplyPromoCode({
    onCompleted: ({ applyPromoCodeV3: response }) => {
      switch (response.__typename) {
        case 'CartResponse':
          showToast('Promo code applied', DoneIcon)
          break
        case 'CartOutOfStockError':
          showToast('Item out of stock', WarningOutlineIcon)
          break
        case 'ApplyPromoCodeError':
        case 'CartModificationError':
        default:
          showToast('Error applying promo code', WarningOutlineIcon)
          break
      }
    },
    onError: (error) => {
      showToast('Error applying promo code', WarningOutlineIcon)
      logError(getErrorMessage(error))
    }
  })

  const handleApplyPromoCode = useCallback(
    (promoCodeBanner: IPromoCodeBanner) => {
      if (isApplied) {
        return
      }

      if (!cartGuid) {
        showToast('Error applying promo code', WarningOutlineIcon)
        return
      }

      applyPromoCode({
        variables: {
          input: {
            cartGuid,
            promoCode: promoCodeBanner.promoCode
          }
        }
      })
    },
    [applyPromoCode, cartGuid, isApplied, showToast]
  )

  return (
    <PromoCodeBannerUIComponent
      banner={banner}
      className={className}
      onApply={handleApplyPromoCode}
      status={getPromoCodeBannerStatus(isApplying, isApplied)}
      testId={testId}
    />
  )
}

export const getPromoCodeBannerStatus = (
  isApplying: boolean,
  isApplied: boolean
) => {
  if (isApplying) {
    return 'APPLYING'
  }

  return isApplied ? 'APPLIED' : 'UNAPPLIED'
}

export const applyPromoCodeInSession = (
  promoCodeBanner: IPromoCodeBanner,
  restaurantGuid?: string
) => {
  if (!restaurantGuid) {
    return
  }

  try {
    dispatchAppliedPromoCode(restaurantGuid, {
      bannerGuid: promoCodeBanner.bannerGuid,
      promoCode: promoCodeBanner.promoCode
    })
  } catch (error) {
    logError({ message: getErrorMessage(error) })
  }
}

export const toPromoCodeBanner = (
  banner: IPromoCodeBanner
): IPromoCodeBanner => ({ ...banner, type: 'PROMO_CODE' })
