import * as React from 'react'
import { ApolloProvider } from '@apollo/client'

import { getApolloClient } from 'cornucopia-apis'
import { useOOGlobalPropsContext } from '@local/service'

export interface ClientProviderProps {
  children: React.ReactNode
}

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const { mode } = useOOGlobalPropsContext()
  let clientConfig =
    mode === 'OO'
      ? { PKG_NAME: 'takeout-web' }
      : {
          PKG_NAME: process.env.PKG_NAME,
          PKG_VERSION: process.env.PKG_VERSION
        }
  return (
    <ApolloProvider client={getApolloClient(clientConfig, true)}>
      {children}
    </ApolloProvider>
  )
}
