import React from 'react'

import {
  IPromoCodeBanner,
  PromoCodeBanner
} from '@toasttab/buffet-patterns-promo-banners'
import {
  ItemOfferBadge as ItemOfferBadgeType,
  ItemOfferBadgeParams,
  ItemOfferBadgeParamsProvider,
  useGetItemOfferBadges
} from '@local/service'
import { logError } from '@local/logging'
import { useBanquetProps } from 'banquet-runtime-modules'

import { CartListener } from './CartListener'

export const ItemOfferBadge = (params: ItemOfferBadgeParams) => {
  const { restaurantInfo: { restaurantGuid = '' } = {} } = useBanquetProps()
  const { itemGuid } = params

  const {
    data: { itemOfferBadges } = {},
    error: itemOfferBadgesQueryError,
    loading
  } = useGetItemOfferBadges({
    skip: !itemGuid || !restaurantGuid,
    variables: {
      input: {
        itemGuid,
        restaurantGuid
      }
    }
  })

  if (itemOfferBadgesQueryError) {
    logError(itemOfferBadgesQueryError)
    return <></>
  }

  const badge = itemOfferBadges?.itemOfferBadges?.[0]

  if (loading || !badge) {
    return <></>
  }

  return (
    <ItemOfferBadgeParamsProvider params={params}>
      <div data-pb='true'>
        <CartListener itemBadge={badge} />
        <PromoCodeBanner
          banner={itemOfferBadgeDataToBanner(badge)}
          testId='item-offer-badge'
        />
      </div>
    </ItemOfferBadgeParamsProvider>
  )
}

// to display an offer as a promo code banner we create a banner from the offer data
export const itemOfferBadgeDataToBanner = ({
  bannerGuid,
  bannerText,
  promoCode
}: ItemOfferBadgeType): IPromoCodeBanner => ({
  bannerGuid,
  bannerText,
  promoCode,
  type: 'PROMO_CODE_ITEM'
})
