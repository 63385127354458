import React from 'react'
import classNames from 'classnames'

import { IconProps } from '@toasttab/buffet-pui-icons'

export type ToastProps = {
  children: React.ReactNode
  className?: string
  Icon?: React.FC<IconProps>
}

export const Toast = ({ children, className, Icon }: ToastProps) => (
  <div
    className={classNames(
      'flex flex-row items-center justify-center',
      className
    )}
  >
    {Icon && <Icon className='mr-3' size='sm' testId='toast-icon' />}
    <span>{children}</span>
  </div>
)
