import { gql, MutationHookOptions, useMutation } from '@apollo/client'
import { MockedResponse } from '@apollo/client/testing'

import { CartResponse, newCartResponse } from '../useGetCart'

export interface ApplyPromoCodeInput {
  input: {
    cartGuid: string
    promoCode: string
  }
}

export type ApplyPromoCodeMutation = {
  applyPromoCodeV3:
    | {
        cart: CartResponse
        __typename?: 'CartResponse'
      }
    | {
        __typename?: 'CartModificationError'
        code: 'CART_CONTENT_NOT_FOUND' | 'MODIFIER_CONFIGURATION_MISMATCH'
        message: string
      }
    | { __typename?: 'CartOutOfStockError'; message: string }
    | { __typename?: 'ApplyPromoCodeError' }
}

export const APPLY_PROMO_CODE = gql`
  mutation APPLY_PROMO_CODE($input: ApplyPromoCodeInputV3!) {
    applyPromoCodeV3(input: $input) {
      ... on CartResponse {
        cart {
          guid
          order {
            discounts {
              restaurantDiscount {
                promoCode
              }
            }
          }
        }
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        message
      }
    }
  }
`

export const useApplyPromoCode = (
  options: MutationHookOptions<ApplyPromoCodeMutation, ApplyPromoCodeInput> = {}
) => {
  return useMutation<ApplyPromoCodeMutation, ApplyPromoCodeInput>(
    APPLY_PROMO_CODE,
    options
  )
}

// for mocking
export const newApplyPromoCodeMutation = (
  cartGuid: string,
  promoCode: string,
  data: ApplyPromoCodeMutation | Error
): MockedResponse<ApplyPromoCodeMutation> => {
  return {
    request: {
      query: APPLY_PROMO_CODE,
      variables: {
        input: {
          cartGuid,
          promoCode
        }
      }
    },
    result: { data: data instanceof Error ? undefined : data },
    error: data instanceof Error ? data : undefined
  }
}

export const newApplyPromoCodeMutationData = (
  cartGuid: string,
  promoCode: string,
  type:
    | 'CartResponse'
    | 'CartModificationError'
    | 'CartOutOfStockError'
    | 'ApplyPromoCodeError'
): ApplyPromoCodeMutation => {
  switch (type) {
    case 'CartResponse':
      return {
        applyPromoCodeV3: {
          cart: newCartResponse(cartGuid, promoCode),
          __typename: 'CartResponse'
        }
      }
    case 'CartModificationError':
      return {
        applyPromoCodeV3: {
          __typename: 'CartModificationError',
          code: 'CART_CONTENT_NOT_FOUND',
          message: 'CartModificationError'
        }
      }
    case 'CartOutOfStockError':
      return {
        applyPromoCodeV3: {
          __typename: 'CartOutOfStockError',
          message: 'CartOutOfStockError'
        }
      }
    case 'ApplyPromoCodeError':
    default:
      return {
        applyPromoCodeV3: {
          __typename: 'ApplyPromoCodeError'
        }
      }
  }
}
