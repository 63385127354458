import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { MockedResponse } from '@apollo/client/testing'

import { usePromoBannersParamsContext } from '../ParamsProviders'

export type GetCartInput = {
  guid: string
}

export type CartResponse = {
  guid: string
  order?: {
    discounts: {
      restaurantDiscount?: {
        promoCode: string
        __typename?: 'RestaurantDiscount'
      } | null
      __typename?: 'Discounts'
    }
    __typename?: 'Order'
  } | null
  __typename?: 'Cart'
}

export type GetCartResponse = {
  cartV2: {
    cart: CartResponse
    __typename: 'CartResponse'
  }
}

export type GetCartQueryOptions = QueryHookOptions<
  GetCartResponse,
  GetCartInput
>

const GET_CART = gql`
  query GET_CART($guid: ID!) {
    cartV2(guid: $guid) {
      ... on CartResponse {
        cart {
          guid
          order {
            discounts {
              restaurantDiscount {
                promoCode
              }
            }
          }
        }
      }
      ... on CartError {
        code
        message
      }
    }
  }
`

export const useGetCart = (options: GetCartQueryOptions = {}) => {
  const { cartGuid = '' } = usePromoBannersParamsContext()

  return useQuery<GetCartResponse, GetCartInput>(GET_CART, {
    ...options,
    variables: { guid: cartGuid },
    skip: !cartGuid
  })
}

// for mocking
export const newGetCartResponse = (
  cartGuid: string,
  data: GetCartResponse | Error
): MockedResponse<GetCartResponse> => {
  return {
    request: {
      query: GET_CART,
      variables: {
        guid: cartGuid
      }
    },
    result: { data: data instanceof Error ? undefined : data },
    error: data instanceof Error ? data : undefined
  }
}

export const newCartResponse = (
  cartGuid: string,
  promoCode: string
): CartResponse => {
  return {
    guid: cartGuid,
    order: {
      discounts: {
        restaurantDiscount: {
          promoCode,
          __typename: 'RestaurantDiscount'
        },
        __typename: 'Discounts'
      },
      __typename: 'Order'
    },
    __typename: 'Cart'
  }
}

export const newGetCartResponseData = (
  cartGuid: string,
  promoCode: string
): GetCartResponse => ({
  cartV2: {
    cart: newCartResponse(cartGuid, promoCode),
    __typename: 'CartResponse'
  }
})
