import React, { useEffect } from 'react'
import cx from 'classnames'
import { useSwipeable } from 'react-swipeable'

import {
  dispatchPromoBannerSeen,
  usePromoBannersSessionData
} from 'cornucopia-apis'
import { getErrorMessage, logError } from '@local/logging'
import { PromoBanner, PromoBannerType } from '../PromoBanner'
import {
  PromoBannersCarouselConfig,
  usePromoBannersParamsContext
} from '@local/service'
import {
  PromoBannersCarouselNextPagination,
  PromoBannersCarouselPrevPagination
} from './PromoBannersCarouselPagination'
import { track } from '@toasttab/do-secundo-analytics'
import { useBanquetProps } from 'banquet-runtime-modules'

export interface PromoBannersCarouselProps {
  promoBanners: PromoBannerType[]
  testId?: string
}

export const DEFAULT_CAROUSEL_CONFIG = {
  pagination: true,
  slideGap: 0,
  xOffset: 0
}

export const PromoBannersCarousel = ({
  promoBanners,
  testId = 'promo-banners-carousel'
}: PromoBannersCarouselProps) => {
  const { carouselConfig } = usePromoBannersParamsContext()
  const { restaurantInfo: { restaurantGuid } = {} } = useBanquetProps()
  const promoBannersSessionData = usePromoBannersSessionData(restaurantGuid)
  const [visibleBannerIndex, setVisibleBannerIndex] = React.useState(0)
  const numberOfBanners = promoBanners.length
  const { pagination } = getCarouselConfigWithDefaults(carouselConfig)
  const showPagination = numberOfBanners > 1 && pagination
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => updateBannerIndex(visibleBannerIndex + 1),
    onSwipedRight: () => updateBannerIndex(visibleBannerIndex - 1),
    preventScrollOnSwipe: true
  })

  const updateBannerIndex = (newIndex: number) => {
    if (newIndex < 0 || newIndex >= promoBanners.length) {
      return
    }

    setVisibleBannerIndex(newIndex)
  }

  useEffect(() => {
    const activeBanner = promoBanners[visibleBannerIndex]

    if (
      !activeBanner ||
      !restaurantGuid ||
      promoBannersSessionData?.bannersSeen?.[activeBanner.bannerGuid]
    ) {
      return
    }

    try {
      track('Promo Banner - seen', activeBanner)
      if (restaurantGuid) {
        dispatchPromoBannerSeen(restaurantGuid, activeBanner)
      }
    } catch (error) {
      logError({ message: getErrorMessage(error) })
    }
  }, [
    promoBanners,
    promoBannersSessionData,
    restaurantGuid,
    visibleBannerIndex
  ])

  if (numberOfBanners === 0) {
    return <></>
  }

  return (
    <div
      className='flex flex-row gap-x-3'
      data-testid={testId}
      {...swipeHandlers}
    >
      {showPagination && (
        <PromoBannersCarouselPrevPagination
          disabled={visibleBannerIndex === 0}
          onClick={() => {
            setVisibleBannerIndex(visibleBannerIndex - 1)
          }}
          testId={`${testId}-previous-button`}
        />
      )}
      <div
        className='w-full overflow-hidden rounded'
        data-testid={`${testId}-wrapper`}
      >
        <div
          className={cx('flex transition-transform duration-200 ease-in-out')}
          data-testid={`${testId}-slides`}
          style={getSlidesWrapperStyles(
            visibleBannerIndex,
            numberOfBanners,
            carouselConfig
          )}
        >
          {promoBanners.map((banner, index) => (
            <div
              aria-current={index === visibleBannerIndex}
              className='flex-grow flex-shrink-0 max-w-full'
              key={banner.bannerGuid}
              style={getSlideStyles(numberOfBanners, carouselConfig)}
              data-testid={`${testId}-slide-${index}`}
            >
              <PromoBanner
                banner={banner}
                className='h-full'
                testId={banner.bannerGuid}
              />
            </div>
          ))}
        </div>
      </div>
      {showPagination && (
        <PromoBannersCarouselNextPagination
          disabled={visibleBannerIndex === numberOfBanners - 1}
          onClick={() => {
            setVisibleBannerIndex(visibleBannerIndex + 1)
          }}
          testId={`${testId}-next-button`}
        />
      )}
    </div>
  )
}

export const getCarouselConfigWithDefaults = (
  carouselConfig?: PromoBannersCarouselConfig
) =>
  carouselConfig
    ? {
        ...DEFAULT_CAROUSEL_CONFIG,
        ...carouselConfig
      }
    : DEFAULT_CAROUSEL_CONFIG

export const getSlidesWrapperStyles = (
  visibleIndex: number,
  numberOfSlides: number,
  carouselConfig?: PromoBannersCarouselConfig
) => {
  if (numberOfSlides === 1) {
    return {}
  }

  const { slideGap } = getCarouselConfigWithDefaults(carouselConfig)
  const visibleSlideXPos = -1 * 100 * visibleIndex
  const offset = getSlidesWrapperOffset(visibleIndex, carouselConfig)

  return {
    gap: slideGap,
    transform: offset
      ? `translateX(calc(${visibleSlideXPos}% + ${offset}px))`
      : `translateX(${visibleSlideXPos}%)`
  }
}

export const getSlidesWrapperOffset = (
  visibleIndex: number,
  carouselConfig?: PromoBannersCarouselConfig
) => {
  const { slideGap, xOffset } = getCarouselConfigWithDefaults(carouselConfig)

  if (visibleIndex === 0) {
    return xOffset
  }

  return (
    2 * xOffset * visibleIndex -
    slideGap * (visibleIndex + 1) +
    xOffset +
    slideGap
  )
}

export const getSlideStyles = (
  numberOfSlides: number,
  carouselConfig?: PromoBannersCarouselConfig
) => {
  const { xOffset } = getCarouselConfigWithDefaults(carouselConfig)

  if (numberOfSlides === 1) {
    return { margin: `0px ${xOffset}px` }
  }

  const basis = xOffset === 0 ? '100%' : `calc(100% - ${xOffset * 2}px)`

  return {
    flexBasis: basis,
    maxWidth: basis
  }
}
