import { logError } from '../../../utils/error-logger'

// util for handling an unknown error for logging (e.g. from a catch)
const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message
  }

  return String(error)
}

export { getErrorMessage, logError }
