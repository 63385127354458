import * as React from 'react'
import { ToastContainer } from 'react-toastify'

import {
  ClientProvider,
  OOGlobalPropsProvider,
  OOGlobalProps
} from '@local/service'
import { ItemOfferBadge } from '@local/item-offer-badge'
import { PromoBanners } from '@local/promo-banners'
import { StockBox, StockBoxConfig } from 'cornucopia-apis'

const stockConfig: StockBoxConfig = {
  shelves: [
    {
      name: 'corn-menu-banners',
      components: [
        {
          component: PromoBanners,
          componentId: 'promo-banners',
          priority: 1
        }
      ]
    },
    {
      name: 'corn-menu-item-detail-banners',
      components: [
        {
          component: ItemOfferBadge,
          componentId: 'item-detail-banners',
          priority: 1
        }
      ]
    }
  ]
}

export function App(ooGlobalProps: OOGlobalProps) {
  return (
    <OOGlobalPropsProvider ooGlobalProps={ooGlobalProps}>
      <ClientProvider>
        <StockBox stockConfig={stockConfig} />
        {/* @ts-ignore valid but TS has issue with component */}
        <ToastContainer
          hideProgressBar
          closeButton={false}
          position='top-right'
        />
      </ClientProvider>
    </OOGlobalPropsProvider>
  )
}
